/* eslint-disable import/no-cycle */
import Vue from 'vue'
import VueRouter from 'vue-router'
// import { isActiveToken, getHomeRouteForLoggedInUser } from '@/auth/utils'

import auth from './auth'
import client from './client'
import xyz from './xyz'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // {
    //   path: '*',
    //   name: 'coming-soon',
    //   component: () => import('@/views/error/ComingSoon.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/*',
    //   name: 'coming-soon',
    //   component: () => import('@/views/error/ComingSoon.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/',
      redirect: '/login',
    },
    ...auth,
    ...client,
    ...xyz,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/coming-soon',
    //   name: 'coming-soon',
    //   component: () => import('@/views/error/ComingSoon.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
  ],
})

// router.beforeEach((to, _, next) => {
//   const publicPages = ['coming-soon', 'login', 'register', 'forgot-password', 'reset-password', 'confirm-account']
//   const authRequired = !publicPages.includes(to.name)
//   const active = isActiveToken()

//   if (to.meta.redirectIfLoggedIn && active) {
//     next(getHomeRouteForLoggedInUser())
//   }

//   if (authRequired && !active) {
//     next('login')
//   } else {
//     next()
//   }

//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
