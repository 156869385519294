// eslint-disable-next-line import/no-cycle
import router from '@/router/index'
import token from '@/libs/storage/token'
import user from '@/libs/storage/user'

// Check is user have valid token
export const isActiveToken = () => {
  const userToken = token.get() != null ? token.get() : null

  if (userToken) {
    const time = Date.parse(new Date()) / 1000
    const expireDate = token.decodeToken()

    if (time > expireDate) {
      return false
    }
    return true
  }
  return false
}

// redirect user using roles
export const getHomeRouteForLoggedInUser = () => {
  const userData = user.get() != null ? user.get() : null
  if (userData) {
    const { roles } = userData
    if (roles === 'XJZT') {
      return { name: 'admin-home' }
    }
    if (roles === 'CLIENT') {
      return { name: 'client-home' }
    }
  }
  return { name: 'login' }
}

// get user roles
export const getPrivileges = () => {
  const userData = user.get() != null ? user.get() : null
  if (userData) {
    const { roles } = userData
    if (roles === 'XJZT') {
      return 'admin'
    }
    if (roles === 'CLIENT') {
      return 'client'
    }
  }
  return null
}

// logout user
export const logoutUser = () => {
  token.remove()
  user.remove()
  router.push({ name: 'login' })
}
