import axios from '@/libs/axios'

export default {
  state: {
    faqSectionProcess: null,
    faqSectionSuccess: null,
    faqSectionError: null,

    updateFaqSectionProcess: null,
    updateFaqSectionSuccess: null,
    updateFaqSectionError: null,

    deleteFaqSectionProcess: null,
    deleteFaqSectionSuccess: null,
    deleteFaqSectionError: null,
  },
  getters: {
    faqSectionProcess: state => state.faqSectionProcess,
    faqSectionSuccess: state => state.faqSectionSuccess,
    faqSectionError: state => state.faqSectionError,

    updateFaqSectionProcess: state => state.updateFaqSectionProcess,
    updateFaqSectionSuccess: state => state.updateFaqSectionSuccess,
    updateFaqSectionError: state => state.updateFaqSectionError,

    deleteFaqSectionProcess: state => state.deleteFaqSectionProcess,
    deleteFaqSectionSuccess: state => state.deleteFaqSectionSuccess,
    deleteFaqSectionError: state => state.deleteFaqSectionError,
  },
  mutations: {

    ADD_FAQ_SECTION_PROCESS(state, payload) {
      state.faqSectionProcess = payload
    },
    ADD_FAQ_SECTION_SUCCESS(state, payload) {
      state.faqSectionSuccess = payload
    },
    ADD_FAQ_SECTION_ERROR(state, payload) {
      state.faqSectionError = payload
    },

    CLEAR_ADD_FAQ_SECTION(state) {
      state.faqSectionError = null
      state.faqSectionProcess = null
      state.faqSectionSuccess = null
    },

    UPDATE_FAQ_SECTION_PROCESS(state, payload) {
      state.updateFaqSectionProcess = payload
    },
    UPDATE_FAQ_SECTION_SUCCESS(state, payload) {
      state.updateFaqSectionSuccess = payload
    },
    UPDATE_FAQ_SECTION_ERROR(state, payload) {
      state.updateFaqSectionError = payload
    },
    CLEAR_UPDATE_FAQ_SECTION(state) {
      state.updateFaqSectionError = null
      state.updateFaqSectionProcess = null
      state.updateFaqSectionSuccess = null
    },

    DELETE_FAQ_SECTION_PROCESS(state, payload) {
      state.deleteFaqSectionProcess = payload
    },
    DELETE_FAQ_SECTION_SUCCESS(state, payload) {
      state.deleteFaqSectionSuccess = payload
    },
    DELETE_FAQ_SECTION_ERROR(state, payload) {
      state.deleteFaqSectionError = payload
    },
    CLEAR_DELETE_FAQ_SECTION(state) {
      state.deleteFaqSectionError = null
      state.deleteFaqSectionProcess = null
      state.deleteFaqSectionSuccess = null
    },
  },
  actions: {
    newFaqSection({ commit, rootState }, payload) {
      commit('CLEAR_ADD_FAQ_SECTION')
      commit('ADD_FAQ_SECTION_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/faq-add',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('ADD_FAQ_SECTION_PROCESS', false)
          commit('ADD_FAQ_SECTION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('ADD_FAQ_SECTION_PROCESS', false)
          if (error.response) {
            commit('ADD_FAQ_SECTION_ERROR', error.response)
          } else if (error.request) {
            commit('ADD_FAQ_SECTION_ERROR', error.request)
          } else {
            commit('ADD_FAQ_SECTION_ERROR', error.message)
          }
        })
    },
    updateFaqSection({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_FAQ_SECTION')
      commit('UPDATE_FAQ_SECTION_PROCESS', true)
      const data = JSON.stringify({
        section: payload.section,
      })
      axios({
        method: 'post',
        url: `/faq-update/${payload.id}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
        data,
      })
        .then(res => {
          commit('UPDATE_FAQ_SECTION_PROCESS', false)
          commit('UPDATE_FAQ_SECTION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_FAQ_SECTION_PROCESS', false)
          if (error.response) {
            commit('UPDATE_FAQ_SECTION_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_FAQ_SECTION_ERROR', error.request)
          } else {
            commit('UPDATE_FAQ_SECTION_ERROR', error.message)
          }
        })
    },
    deleteFaqSection({ commit, rootState }, payload) {
      commit('CLEAR_DELETE_FAQ_SECTION')
      commit('DELETE_FAQ_SECTION_PROCESS', true)
      axios({
        method: 'delete',
        url: `/faq-delete/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(() => {
          commit('DELETE_FAQ_SECTION_PROCESS', false)
          commit('DELETE_FAQ_SECTION_SUCCESS', true)
        })
        .catch(error => {
          commit('DELETE_FAQ_SECTION_PROCESS', false)
          if (error.response) {
            commit('DELETE_FAQ_SECTION_ERROR', error.response)
          } else if (error.request) {
            commit('DELETE_FAQ_SECTION_ERROR', error.request)
          } else {
            commit('DELETE_FAQ_SECTION_ERROR', error.message)
          }
        })
    },
  },
}
