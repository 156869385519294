import axios from '@/libs/axios'

export default {
  state: {
    newReviewProcess: null,
    newReviewSuccess: null,
    newReviewError: null,

    updateReviewProcess: null,
    updateReviewSuccess: null,
    updateReviewError: null,

    deleteReviewProcess: null,
    deleteReviewSuccess: null,
    deleteReviewError: null,
  },
  getters: {
    newReviewProcess: state => state.newReviewProcess,
    newReviewSuccess: state => state.newReviewSuccess,
    newReviewError: state => state.newReviewError,

    updateReviewProcess: state => state.updateReviewProcess,
    updateReviewSuccess: state => state.updateReviewSuccess,
    updateReviewError: state => state.updateReviewError,

    deleteReviewProcess: state => state.deleteReviewProcess,
    deleteReviewSuccess: state => state.deleteReviewSuccess,
    deleteReviewError: state => state.deleteReviewError,
  },
  mutations: {

    ADD_REVIEW_PROCESS(state, payload) {
      state.newReviewProcess = payload
    },
    ADD_REVIEW_SUCCESS(state, payload) {
      state.newReviewSuccess = payload
    },
    ADD_REVIEW_ERROR(state, payload) {
      state.newReviewError = payload
    },

    CLEAR_ADD_REVIEW(state) {
      state.newReviewError = null
      state.newReviewProcess = null
      state.newReviewSuccess = null
    },

    UPDATE_REVIEW_PROCESS(state, payload) {
      state.updateReviewProcess = payload
    },
    UPDATE_REVIEW_SUCCESS(state, payload) {
      state.updateReviewSuccess = payload
    },
    UPDATE_REVIEW_ERROR(state, payload) {
      state.updateReviewError = payload
    },
    CLEAR_UPDATE_REVIEW(state) {
      state.updateReviewError = null
      state.updateReviewProcess = null
      state.updateReviewSuccess = null
    },

    DELETE_REVIEW_PROCESS(state, payload) {
      state.deleteReviewProcess = payload
    },
    DELETE_REVIEW_SUCCESS(state, payload) {
      state.deleteReviewSuccess = payload
    },
    DELETE_REVIEW_ERROR(state, payload) {
      state.deleteReviewError = payload
    },
    CLEAR_DELETE_REVIEW(state) {
      state.deleteReviewError = null
      state.deleteReviewProcess = null
      state.deleteReviewSuccess = null
    },
  },
  actions: {
    newReview({ commit, rootState }, payload) {
      commit('CLEAR_ADD_REVIEW')
      commit('ADD_REVIEW_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/add-review',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('ADD_REVIEW_PROCESS', false)
          commit('ADD_REVIEW_SUCCESS', res.data)
        })
        .catch(error => {
          commit('ADD_REVIEW_PROCESS', false)
          if (error.response) {
            commit('ADD_REVIEW_ERROR', error.response)
          } else if (error.request) {
            commit('ADD_REVIEW_ERROR', error.request)
          } else {
            commit('ADD_REVIEW_ERROR', error.message)
          }
        })
    },
    updateReview({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_REVIEW')
      commit('UPDATE_REVIEW_PROCESS', true)
      axios({
        method: 'put',
        url: `/review-update/${payload.id}/${payload.state}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('UPDATE_REVIEW_PROCESS', false)
          commit('UPDATE_REVIEW_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_REVIEW_PROCESS', false)
          if (error.response) {
            commit('UPDATE_REVIEW_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_REVIEW_ERROR', error.request)
          } else {
            commit('UPDATE_REVIEW_ERROR', error.message)
          }
        })
    },
    deleteReview({ commit, rootState }, payload) {
      commit('CLEAR_DELETE_REVIEW')
      commit('DELETE_REVIEW_PROCESS', true)
      axios({
        method: 'delete',
        url: `/review-delete/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('DELETE_REVIEW_PROCESS', false)
          commit('DELETE_REVIEW_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_REVIEW_PROCESS', false)
          if (error.response) {
            commit('DELETE_REVIEW_ERROR', error.response)
          } else if (error.request) {
            commit('DELETE_REVIEW_ERROR', error.request)
          } else {
            commit('DELETE_REVIEW_ERROR', error.message)
          }
        })
    },
  },
}
