import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
// import 'vue-tel-input/dist/vue-tel-input.css'
import 'vue-tel-input/dist/css/sprite.css'
import 'vue-tel-input/dist/css/component.css'

const countries = ['BF', 'CM', 'CI', 'GN', 'ML', 'SN']

const options = {
  defaultCountry: 'CI',
  onlyCountries: countries,
  dropdownOptions: {
    showDialCodeInList: false,
    showDialCodeInSelection: true,
    showFlags: true,
  },
  inputOptions: {
    placeholder: 'Entrer le numéro',
    required: true,
    name: 'phone',
    id: 'tel-input',
  },
  invalidMsg: 'Format invalide',
  styleClasses: {
    class: 'form-control',
  },
}
Vue.use(VueTelInput, options)
