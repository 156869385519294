import axios from '@/libs/axios'
import { getPrivileges } from '@/auth/utils'

export default {
  state: {
    exchangeProcess: null,
    exchangeSuccess: null,
    exchangeError: null,

    closeExchangeProcess: null,
    closeExchangeSuccess: null,
    closeExchangeError: null,

    closeCryptoProcess: null,
    closeCryptoSuccess: null,
    closeCryptoError: null,

    closeFirstManualProcess: null,
    closeFirstManualSuccess: null,
    closeFirstManualError: null,

    closeSecondManualProcess: null,
    closeSecondManualSuccess: null,
    closeSecondManualError: null,

    manualCashinProcess: null,
    manualCashinSuccess: null,
    manualCashinError: null,

    changeGlobalStateProcess: null,
    changeGlobalStateSuccess: null,
    changeGlobalStateError: null,
  },
  getters: {
    exchangeProcess: state => state.exchangeProcess,
    exchangeSuccess: state => state.exchangeSuccess,
    exchangeError: state => state.exchangeError,

    changeGlobalStateProcess: state => state.changeGlobalStateProcess,
    changeGlobalStateSuccess: state => state.changeGlobalStateSuccess,
    changeGlobalStateError: state => state.changeGlobalStateError,

    manualCashinProcess: state => state.manualCashinProcess,
    manualCashinSuccess: state => state.manualCashinSuccess,
    manualCashinError: state => state.manualCashinError,

    closeExchangeProcess: state => state.closeExchangeProcess,
    closeExchangeSuccess: state => state.closeExchangeSuccess,
    closeExchangeError: state => state.closeExchangeError,

    closeCryptoProcess: state => state.closeCryptoProcess,
    closeCryptoSuccess: state => state.closeCryptoSuccess,
    closeCryptoError: state => state.closeCryptoError,

    closeFirstManualProcess: state => state.closeFirstManualProcess,
    closeFirstManualSuccess: state => state.closeFirstManualSuccess,
    closeFirstManualError: state => state.closeFirstManualError,

    closeSecondManualProcess: state => state.closeSecondManualProcess,
    closeSecondManualSuccess: state => state.closeSecondManualSuccess,
    closeSecondManualError: state => state.closeSecondManualError,
  },
  mutations: {

    MAKE_EXCHANGE_PROCESS(state, payload) {
      state.exchangeProcess = payload
    },
    MAKE_EXCHANGE_SUCCESS(state, payload) {
      state.exchangeSuccess = payload
    },
    MAKE_EXCHANGE_ERROR(state, payload) {
      state.exchangeError = payload
    },

    CLEAR_MAKE_EXCHANGE(state) {
      state.exchangeError = null
      state.exchangeProcess = null
      state.exchangeSuccess = null
    },
    CHANGE_GLOBAL_STATE_PROCESS(state, payload) {
      state.changeGlobalStateProcess = payload
    },
    CHANGE_GLOBAL_STATE_SUCCESS(state, payload) {
      state.changeGlobalStateSuccess = payload
    },
    CHANGE_GLOBAL_STATE_ERROR(state, payload) {
      state.changeGlobalStateError = payload
    },
    CLEAR_CHANGE_GLOBAL_STATE(state) {
      state.changeGlobalStateError = null
      state.changeGlobalStateProcess = null
      state.changeGlobalStateSuccess = null
    },
    CLOSE_EXCHANGE_PROCESS(state, payload) {
      state.closeExchangeProcess = payload
    },
    CLOSE_EXCHANGE_SUCCESS(state, payload) {
      state.closeExchangeSuccess = payload
    },
    CLOSE_EXCHANGE_ERROR(state, payload) {
      state.closeExchangeError = payload
    },

    CLEAR_CLOSE_EXCHANGE(state) {
      state.closeExchangeError = null
      state.closeExchangeProcess = null
      state.closeExchangeSuccess = null
    },
    CLOSE_CRYPTO_PROCESS(state, payload) {
      state.closeCryptoProcess = payload
    },
    CLOSE_CRYPTO_SUCCESS(state, payload) {
      state.closeCryptoSuccess = payload
    },
    CLOSE_CRYPTO_ERROR(state, payload) {
      state.closeCryptoError = payload
    },

    CLEAR_CLOSE_CRYPTO(state) {
      state.closeCryptoError = null
      state.closeCryptoProcess = null
      state.closeCryptoSuccess = null
    },
    CLOSE_FIRST_MANUAL_PROCESS(state, payload) {
      state.closeFirstManualProcess = payload
    },
    CLOSE_FIRST_MANUAL_SUCCESS(state, payload) {
      state.closeFirstManualSuccess = payload
    },
    CLOSE_FIRST_MANUAL_ERROR(state, payload) {
      state.closeFirstManualError = payload
    },

    CLEAR_CLOSE_FIRST_MANUAL(state) {
      state.closeFirstManualError = null
      state.closeFirstManualProcess = null
      state.closeFirstManualSuccess = null
    },
    CLOSE_SECOND_MANUAL_PROCESS(state, payload) {
      state.closeSecondManualProcess = payload
    },
    CLOSE_SECOND_MANUAL_SUCCESS(state, payload) {
      state.closeSecondManualSuccess = payload
    },
    CLOSE_SECOND_MANUAL_ERROR(state, payload) {
      state.closeSecondManualError = payload
    },

    CLEAR_CLOSE_SECOND_MANUAL(state) {
      state.closeSecondManualError = null
      state.closeSecondManualProcess = null
      state.closeSecondManualSuccess = null
    },
    MANUAL_CASHIN_PROCESS(state, payload) {
      state.manualCashinProcess = payload
    },
    MANUAL_CASHIN_SUCCESS(state, payload) {
      state.manualCashinSuccess = payload
    },
    MANUAL_CASHIN_ERROR(state, payload) {
      state.manualCashinError = payload
    },

    CLEAR_MANUAL_CASHIN(state) {
      state.manualCashinError = null
      state.manualCashinProcess = null
      state.manualCashinSuccess = null
    },
  },
  actions: {
    makeExchange({ commit, rootState }, payload) {
      commit('CLEAR_MAKE_EXCHANGE')
      commit('MAKE_EXCHANGE_PROCESS', true)
      const data = JSON.stringify({
        sentDevCode: payload.sentDevCode,
        receivedDevCode: payload.receivedDevCode,
        sentMemo: payload.sentMemo,
        sentValue: payload.sentValue,
        fileName: payload.fileName,
        manualRecipientAddressId: payload.manualRecipientAddressId,
        sentPaymentId: payload.sentPaymentId,
        realSentValue: payload.realSentValue,
        receivedValue: payload.receivedValue,
        successCallBackUrl: getPrivileges() === 'client' ? `${window.location.protocol}//${window.location.hostname}/history` : `${window.location.protocol}//${window.location.hostname}/admin-history`,
        errorCallBackUrl: getPrivileges() === 'client' ? `${window.location.protocol}//${window.location.hostname}/history` : `${window.location.protocol}//${window.location.hostname}/admin-history`,
        visaLastDigit: payload.visaLastDigit,
        visaCardId: payload.visaCardId,
        mobileInBenefit: payload.mobileInBenefit,
        mobileOutBenefit: payload.mobileOutBenefit,
        senderAddressName: payload.senderAddressName,
        receiverAddressName: payload.receiverAddressName,
        receiverAddress: payload.receiverAddress,
        senderAddress: payload.senderAddress,
        otp: payload.otp,
      })
      axios({
        method: 'post',
        url: '/start-exchange-transaction',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          commit('MAKE_EXCHANGE_PROCESS', false)
          commit('MAKE_EXCHANGE_SUCCESS', res)
        })
        .catch(error => {
          commit('MAKE_EXCHANGE_PROCESS', false)
          if (error.response) {
            commit('MAKE_EXCHANGE_ERROR', error.response)
          } else if (error.request) {
            commit('MAKE_EXCHANGE_ERROR', error.request)
          } else {
            commit('MAKE_EXCHANGE_ERROR', error.message)
          }
        })
    },
    closeMobileExchange({ commit, rootState }, payload) {
      commit('CLEAR_CLOSE_EXCHANGE')
      commit('CLOSE_EXCHANGE_PROCESS', true)
      axios({
        method: 'post',
        url: `/end-waiting-transaction/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('CLOSE_EXCHANGE_PROCESS', false)
          commit('CLOSE_EXCHANGE_SUCCESS', res)
        })
        .catch(error => {
          commit('CLOSE_EXCHANGE_PROCESS', false)
          if (error.response) {
            commit('CLOSE_EXCHANGE_ERROR', error.response.data.message)
          } else if (error.request) {
            commit('CLOSE_EXCHANGE_ERROR', error.request)
          } else {
            commit('CLOSE_EXCHANGE_ERROR', error.message)
          }
        })
    },
    resolveCryptoExchange({ commit, rootState }, payload) {
      commit('CLEAR_CLOSE_CRYPTO')
      commit('CLOSE_CRYPTO_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/resolve-crypto-transaction',
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
        data,
      })
        .then(res => {
          commit('CLOSE_CRYPTO_PROCESS', false)
          commit('CLOSE_CRYPTO_SUCCESS', res)
        })
        .catch(error => {
          commit('CLOSE_CRYPTO_PROCESS', false)
          if (error.response) {
            commit('CLOSE_CRYPTO_ERROR', error.response.data.message)
          } else if (error.request) {
            commit('CLOSE_CRYPTO_ERROR', error.request)
          } else {
            commit('CLOSE_CRYPTO_ERROR', error.message)
          }
        })
    },
    closeFirstManualTransaction({ commit, rootState }, payload) {
      commit('CLEAR_CLOSE_FIRST_MANUAL')
      commit('CLOSE_FIRST_MANUAL_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/treat-manual-sent-exchange-transaction',
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
        data,
      })
        .then(res => {
          commit('CLOSE_FIRST_MANUAL_PROCESS', false)
          commit('CLOSE_FIRST_MANUAL_SUCCESS', res)
        })
        .catch(error => {
          commit('CLOSE_FIRST_MANUAL_PROCESS', false)
          if (error.response) {
            commit('CLOSE_FIRST_MANUAL_ERROR', error.response.data.message)
          } else if (error.request) {
            commit('CLOSE_FIRST_MANUAL_ERROR', error.request)
          } else {
            commit('CLOSE_FIRST_MANUAL_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_CLOSE_FIRST_MANUAL')
          }, 3000)
        })
    },
    closeSecondManualTransaction({ commit, rootState }, payload) {
      commit('CLEAR_CLOSE_SECOND_MANUAL')
      commit('CLOSE_SECOND_MANUAL_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/close-exchange-transaction',
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
        data,
      })
        .then(res => {
          commit('CLOSE_SECOND_MANUAL_PROCESS', false)
          commit('CLOSE_SECOND_MANUAL_SUCCESS', res.data)
        })
        .catch(error => {
          commit('CLOSE_SECOND_MANUAL_PROCESS', false)
          if (error.response) {
            commit('CLOSE_SECOND_MANUAL_ERROR', error.response.data.message)
          } else if (error.request) {
            commit('CLOSE_SECOND_MANUAL_ERROR', error.request)
          } else {
            commit('CLOSE_SECOND_MANUAL_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_CLOSE_SECOND_MANUAL')
          }, 3000)
        })
    },
    makeManualCashin({ commit, rootState }, payload) {
      commit('CLEAR_MANUAL_CASHIN')
      commit('MANUAL_CASHIN_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/set-up-manual-cash-in',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('MANUAL_CASHIN_PROCESS', false)
          commit('MANUAL_CASHIN_SUCCESS', res.data)
        })
        .catch(error => {
          commit('MANUAL_CASHIN_PROCESS', false)
          if (error.response) {
            commit('MANUAL_CASHIN_ERROR', error.response)
          } else if (error.request) {
            commit('MANUAL_CASHIN_ERROR', error.request)
          } else {
            commit('MANUAL_CASHIN_ERROR', error.message)
          }
        })
    },
    changeTransactionGlobalState({ commit, rootState }, payload) {
      commit('CLEAR_CHANGE_GLOBAL_STATE')
      commit('CHANGE_GLOBAL_STATE_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/update-transaction-state',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('CHANGE_GLOBAL_STATE_PROCESS', false)
          commit('CHANGE_GLOBAL_STATE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('CHANGE_GLOBAL_STATE_PROCESS', false)
          if (error.response) {
            commit('CHANGE_GLOBAL_STATE_ERROR', error.response)
          } else if (error.request) {
            commit('CHANGE_GLOBAL_STATE_ERROR', error.request)
          } else {
            commit('CHANGE_GLOBAL_STATE_ERROR', error.message)
          }
        })
    },
  },
}
