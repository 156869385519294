const CryptoJS = require('crypto-js')

class Encrypt {
  constructor() {
    this.tokenKey = '9nTzSW@Rc_Aq2fQnBHMva+pLdX#cX$%e?sp_C8ckj2WwAdVwt@LM9gRDP^-R5ezNW+AE$YxvFn8aD$gX286zACEReRPR$QH3L=55*Fa^x#gq*QqR=qTKSX4ghwDUjMZ3H8s=@4H%SzVZ6Qg%*zNXwZxg@XeVeM9nUYCnYWL9vSpcSFZye52^9B=SScQr@V!V?Ae^AEEjpXv=9=-rv6!yz'
  }

  // Token encryption
  encodeToken(data) {
    return CryptoJS.AES.encrypt(data, this.tokenKey).toString()
  }

  // eslint-disable-next-line class-methods-use-this
  encode(data) {
    return CryptoJS.SHA256(JSON.stringify(data)).toString()
  }

  decodeToken(data) {
    const bytes = CryptoJS.AES.decrypt(data, this.tokenKey)
    return bytes.toString(CryptoJS.enc.Utf8)
  }

  encodeUser(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.tokenKey).toString()
  }

  decodeUser(data) {
    const bytes = CryptoJS.AES.decrypt(data, this.tokenKey)
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  }
}

export default new Encrypt()
