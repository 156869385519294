import axios from '@/libs/axios'

export default {
  state: {
    addRequireDocumentProcess: null,
    addRequireDocumentSuccess: null,
    addRequireDocumentError: null,

    updateRequireDocumentProcess: null,
    updateRequireDocumentSuccess: null,
    updateRequireDocumentError: null,

    deleteRequireDocumentProcess: null,
    deleteRequireDocumentSuccess: null,
    deleteRequireDocumentError: null,
  },
  getters: {
    addRequireDocumentProcess: state => state.addRequireDocumentProcess,
    addRequireDocumentSuccess: state => state.addRequireDocumentSuccess,
    addRequireDocumentError: state => state.addRequireDocumentError,

    updateRequireDocumentProcess: state => state.updateRequireDocumentProcess,
    updateRequireDocumentSuccess: state => state.updateRequireDocumentSuccess,
    updateRequireDocumentError: state => state.updateRequireDocumentError,

    deleteRequireDocumentProcess: state => state.deleteRequireDocumentProcess,
    deleteRequireDocumentSuccess: state => state.deleteRequireDocumentSuccess,
    deleteRequireDocumentError: state => state.deleteRequireDocumentError,
  },
  mutations: {
    ADD_REQUIRED_DOCUMENT_PROCESS(state, payload) {
      state.addRequireDocumentProcess = payload
    },
    ADD_REQUIRED_DOCUMENT_SUCCESS(state, payload) {
      state.addRequireDocumentSuccess = payload
    },
    ADD_REQUIRED_DOCUMENT_ERROR(state, payload) {
      state.addRequireDocumentError = payload
    },

    CLEAR_ADD_REQUIRED_DOCUMENT(state) {
      state.addRequireDocumentError = null
      state.addRequireDocumentProcess = null
      state.addRequireDocumentSuccess = null
    },

    UPDATE_REQUIRED_DOCUMENT_PROCESS(state, payload) {
      state.updateRequireDocumentProcess = payload
    },
    UPDATE_REQUIRED_DOCUMENT_SUCCESS(state, payload) {
      state.updateRequireDocumentSuccess = payload
    },
    UPDATE_REQUIRED_DOCUMENT_ERROR(state, payload) {
      state.updateRequireDocumentError = payload
    },
    CLEAR_UPDATE_REQUIRED_DOCUMENT(state) {
      state.updateRequireDocumentError = null
      state.updateRequireDocumentProcess = null
      state.updateRequireDocumentSuccess = null
    },

    DELETE_REQUIRED_DOCUMENT_PROCESS(state, payload) {
      state.deleteRequireDocumentProcess = payload
    },
    DELETE_REQUIRED_DOCUMENT_SUCCESS(state, payload) {
      state.deleteRequireDocumentSuccess = payload
    },
    DELETE_REQUIRED_DOCUMENT_ERROR(state, payload) {
      state.deleteRequireDocumentError = payload
    },
    CLEAR_DELETE_REQUIRED_DOCUMENT(state) {
      state.deleteRequireDocumentError = null
      state.deleteRequireDocumentProcess = null
      state.deleteRequireDocumentSuccess = null
    },
  },
  actions: {
    addRequireDocument({ commit, rootState }, payload) {
      commit('CLEAR_ADD_REQUIRED_DOCUMENT')
      commit('ADD_REQUIRED_DOCUMENT_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/required-kyc-doc/store',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('ADD_REQUIRED_DOCUMENT_PROCESS', false)
          commit('ADD_REQUIRED_DOCUMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('ADD_REQUIRED_DOCUMENT_PROCESS', false)
          if (error.response) {
            commit('ADD_REQUIRED_DOCUMENT_ERROR', error.response)
          } else if (error.request) {
            commit('ADD_REQUIRED_DOCUMENT_ERROR', error.request)
          } else {
            commit('ADD_REQUIRED_DOCUMENT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_ADD_REQUIRED_DOCUMENT')
          }, 3000)
        })
    },
    updateRequireDocument({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_REQUIRED_DOCUMENT')
      commit('UPDATE_REQUIRED_DOCUMENT_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'put',
        url: '/required-kyc-doc/update',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('UPDATE_REQUIRED_DOCUMENT_PROCESS', false)
          commit('UPDATE_REQUIRED_DOCUMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_REQUIRED_DOCUMENT_PROCESS', false)
          if (error.response) {
            commit('UPDATE_REQUIRED_DOCUMENT_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_REQUIRED_DOCUMENT_ERROR', error.request)
          } else {
            commit('UPDATE_REQUIRED_DOCUMENT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_UPDATE_REQUIRED_DOCUMENT')
          }, 3000)
        })
    },
    deleteRequireDocument({ commit, rootState }, payload) {
      commit('CLEAR_DELETE_REQUIRED_DOCUMENT')
      commit('DELETE_REQUIRED_DOCUMENT_PROCESS', true)
      const data = new FormData()
      axios({
        method: 'post',
        url: `/required-kyc-doc/delete/${payload}`,
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('DELETE_REQUIRED_DOCUMENT_PROCESS', false)
          commit('DELETE_REQUIRED_DOCUMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_REQUIRED_DOCUMENT_PROCESS', false)
          if (error.response) {
            commit('DELETE_REQUIRED_DOCUMENT_ERROR', error.response)
          } else if (error.request) {
            commit('DELETE_REQUIRED_DOCUMENT_ERROR', error.request)
          } else {
            commit('DELETE_REQUIRED_DOCUMENT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_DELETE_REQUIRED_DOCUMENT')
          }, 3000)
        })
    },
  },
}
