import axios from '@/libs/axios'

export default {
  state: {
    newSupportProcess: null,
    newSupportSuccess: null,
    newSupportError: null,

    replySupportProcess: null,
    replySupportSuccess: null,
    replySupportError: null,
  },
  getters: {
    newSupportProcess: state => state.newSupportProcess,
    newSupportSuccess: state => state.newSupportSuccess,
    newSupportError: state => state.newSupportError,

    replySupportProcess: state => state.replySupportProcess,
    replySupportSuccess: state => state.replySupportSuccess,
    replySupportError: state => state.replySupportError,
  },
  mutations: {
    ADD_SUPPORT_PROCESS(state, payload) {
      state.newSupportProcess = payload
    },
    ADD_SUPPORT_SUCCESS(state, payload) {
      state.newSupportSuccess = payload
    },
    ADD_SUPPORT_ERROR(state, payload) {
      state.newSupportError = payload
    },

    CLEAR_ADD_SUPPORT(state) {
      state.newSupportError = null
      state.newSupportProcess = null
      state.newSupportSuccess = null
    },

    REPLY_SUPPORT_PROCESS(state, payload) {
      state.replySupportProcess = payload
    },
    REPLY_SUPPORT_SUCCESS(state, payload) {
      state.replySupportSuccess = payload
    },
    REPLY_SUPPORT_ERROR(state, payload) {
      state.replySupportError = payload
    },
    CLEAR_REPLY_SUPPORT(state) {
      state.replySupportError = null
      state.replySupportProcess = null
      state.replySupportSuccess = null
    },
  },
  actions: {
    newSupport({ commit, rootState }, payload) {
      commit('CLEAR_ADD_SUPPORT')
      commit('ADD_SUPPORT_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
        title: payload.title,
        email: payload.email,
        message: payload.message,
        file_name: payload.file_name,
      })
      axios({
        method: 'post',
        url: '/request-support',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          commit('ADD_SUPPORT_PROCESS', false)
          commit('ADD_SUPPORT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('ADD_SUPPORT_PROCESS', false)
          if (error.response) {
            commit('ADD_SUPPORT_ERROR', error.response)
          } else if (error.request) {
            commit('ADD_SUPPORT_ERROR', error.request)
          } else {
            commit('ADD_SUPPORT_ERROR', error.message)
          }
        })
    },
    replySupport({ commit, rootState }, payload) {
      commit('CLEAR_REPLY_SUPPORT')
      commit('REPLY_SUPPORT_PROCESS', true)
      const data = JSON.stringify({
        id: payload.id,
        response: payload.response,
      })
      axios({
        method: 'post',
        url: '/support-reply',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('REPLY_SUPPORT_PROCESS', false)
          commit('REPLY_SUPPORT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('REPLY_SUPPORT_PROCESS', false)
          if (error.response) {
            commit('REPLY_SUPPORT_ERROR', error.response)
          } else if (error.request) {
            commit('REPLY_SUPPORT_ERROR', error.request)
          } else {
            commit('REPLY_SUPPORT_ERROR', error.message)
          }
        })
    },
  },
}
