import axios from '@/libs/axios'

export default {
  state: {
    addCurrencyProcess: null,
    addCurrencySuccess: null,
    addCurrencyError: null,

    updateCurrencyProcess: null,
    updateCurrencySuccess: null,
    updateCurrencyError: null,

    updateSoldeProcess: null,
    updateSoldeSuccess: null,
    updateSoldeError: null,
  },
  getters: {
    addCurrencyProcess: state => state.addCurrencyProcess,
    addCurrencySuccess: state => state.addCurrencySuccess,
    addCurrencyError: state => state.addCurrencyError,

    updateCurrencyProcess: state => state.updateCurrencyProcess,
    updateCurrencySuccess: state => state.updateCurrencySuccess,
    updateCurrencyError: state => state.updateCurrencyError,

    updateSoldeProcess: state => state.updateSoldeProcess,
    updateSoldeSuccess: state => state.updateSoldeSuccess,
    updateSoldeError: state => state.updateSoldeError,
  },
  mutations: {

    ADD_CURRENCY_PROCESS(state, payload) {
      state.addCurrencyProcess = payload
    },
    ADD_CURRENCY_SUCCESS(state, payload) {
      state.addCurrencySuccess = payload
    },
    ADD_CURRENCY_ERROR(state, payload) {
      state.addCurrencyError = payload
    },

    CLEAR_ADD_CURRENCY(state) {
      state.addCurrencyError = null
      state.addCurrencyProcess = null
      state.addCurrencySuccess = null
    },

    UPDATE_CURRENCY_PROCESS(state, payload) {
      state.updateCurrencyProcess = payload
    },
    UPDATE_CURRENCY_SUCCESS(state, payload) {
      state.updateCurrencySuccess = payload
    },
    UPDATE_CURRENCY_ERROR(state, payload) {
      state.updateCurrencyError = payload
    },
    CLEAR_UPDATE_CURRENCY(state) {
      state.updateCurrencyError = null
      state.updateCurrencyProcess = null
      state.updateCurrencySuccess = null
    },

    UPDATE_SOLDE_PROCESS(state, payload) {
      state.updateSoldeProcess = payload
    },
    UPDATE_SOLDE_SUCCESS(state, payload) {
      state.updateSoldeSuccess = payload
    },
    UPDATE_SOLDE_ERROR(state, payload) {
      state.updateSoldeError = payload
    },
    CLEAR_UPDATE_SOLDE(state) {
      state.updateSoldeError = null
      state.updateSoldeProcess = null
      state.updateSoldeSuccess = null
    },
  },
  actions: {
    addCurrency({ commit, rootState }, payload) {
      commit('CLEAR_ADD_CURRENCY')
      commit('ADD_CURRENCY_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/add-tarif',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('ADD_CURRENCY_PROCESS', false)
          commit('ADD_CURRENCY_SUCCESS', res.data)
        })
        .catch(error => {
          commit('ADD_CURRENCY_PROCESS', false)
          if (error.response) {
            commit('ADD_CURRENCY_ERROR', error.response)
          } else if (error.request) {
            commit('ADD_CURRENCY_ERROR', error.request)
          } else {
            commit('ADD_CURRENCY_ERROR', error.message)
          }
        })
    },
    updateCurrency({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_CURRENCY')
      commit('UPDATE_CURRENCY_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/update-tarif',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('UPDATE_CURRENCY_PROCESS', false)
          commit('UPDATE_CURRENCY_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_CURRENCY_PROCESS', false)
          if (error.response) {
            commit('UPDATE_CURRENCY_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_CURRENCY_ERROR', error.request)
          } else {
            commit('UPDATE_CURRENCY_ERROR', error.message)
          }
        })
    },
    updateSolde({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_SOLDE')
      commit('UPDATE_SOLDE_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/update-tarif-value',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('UPDATE_SOLDE_PROCESS', false)
          commit('UPDATE_SOLDE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_SOLDE_PROCESS', false)
          if (error.response) {
            commit('UPDATE_SOLDE_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_SOLDE_ERROR', error.request)
          } else {
            commit('UPDATE_SOLDE_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_UPDATE_SOLDE')
          }, 3000)
        })
    },
  },
}
