import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import auth from './auth'
import validation from './validation'
import currency from './currencies'
import address from './address'
import exchange from './exchange'
import support from './support'
import require from './require-document'
import treat from './treat-document'
import avis from './avis'
import note from './note'
import faqSection from './faq/section'
import faqDetails from './faq/details'

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    validation,
    app,
    currency,
    address,
    exchange,
    support,
    appConfig,
    verticalMenu,
    require,
    treat,
    avis,
    note,
    faqSection,
    faqDetails,
  },
  strict: process.env.DEV,
})
