/* eslint-disable class-methods-use-this */
import encrypt from '@/libs/encrypt'

const USER = 'y_*Uhk-zX39H*5Gt5*#yhp%@DSkL%B7kBFsn-nkRTAfqrxs=%_azejY!9Y*EPR&vqP3f&uNmAegPDVBQSLYp8DcL$_+Z3+'

class User {
  set(value) {
    const data = encrypt.encodeUser(value)
    return window.localStorage.setItem(USER, JSON.stringify(data))
  }

  get() {
    return window.localStorage.getItem(USER) != null
      ? encrypt.decodeUser(JSON.parse(window.localStorage.getItem(USER)))
      : null
  }

  remove() {
    return window.localStorage.removeItem(USER)
  }
}

export default new User()
