import Vue from "vue";

import axios from "axios";
import { logoutUser } from "@/auth/utils";

const axiosIns = axios.create({
  baseURL: "https://api.godwinmulti-dev.com/api",
  // baseURL: 'https://api-godwinmultiservice.ayifa.co/api',
  // baseURL: 'http://localhost:6002/api',
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    // const { config, response: { status } } = error
    const { response } = error;
    if (response && response.status === 401) {
      logoutUser();
    }
    return Promise.reject(error);
  }
);
Vue.prototype.$http = axiosIns;

export default axiosIns;
