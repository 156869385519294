import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/storage'

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmPwTcquffCwQ4S9Yzpy9JKi7OiPMYov0",
  authDomain: "godwinmulti-dev.firebaseapp.com",
  projectId: "godwinmulti-dev",
  storageBucket: "godwinmulti-dev.appspot.com",
  messagingSenderId: "503886638268",
  appId: "1:503886638268:web:925eca10693075fdb3f4b9"
};

firebase.initializeApp(firebaseConfig)

Vue.use(firebase)
