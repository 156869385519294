import axios from '@/libs/axios'

export default {
  state: {
    newAddressProcess: null,
    newAddressSuccess: null,
    newAddressError: null,

    updateAddressProcess: null,
    updateAddressSuccess: null,
    updateAddressError: null,

    deleteAddressProcess: null,
    deleteAddressSuccess: null,
    deleteAddressError: null,
  },
  getters: {
    newAddressProcess: state => state.newAddressProcess,
    newAddressSuccess: state => state.newAddressSuccess,
    newAddressError: state => state.newAddressError,

    updateAddressProcess: state => state.updateAddressProcess,
    updateAddressSuccess: state => state.updateAddressSuccess,
    updateAddressError: state => state.updateAddressError,

    deleteAddressProcess: state => state.deleteAddressProcess,
    deleteAddressSuccess: state => state.deleteAddressSuccess,
    deleteAddressError: state => state.deleteAddressError,
  },
  mutations: {

    NEW_ADDRESS_PROCESS(state, payload) {
      state.newAddressProcess = payload
    },
    NEW_ADDRESS_SUCCESS(state, payload) {
      state.newAddressSuccess = payload
    },
    NEW_ADDRESS_ERROR(state, payload) {
      state.newAddressError = payload
    },

    CLEAR_NEW_ADDRESS(state) {
      state.newAddressError = null
      state.newAddressProcess = null
      state.newAddressSuccess = null
    },

    UPDATE_ADDRESS_PROCESS(state, payload) {
      state.updateAddressProcess = payload
    },
    UPDATE_ADDRESS_SUCCESS(state, payload) {
      state.updateAddressSuccess = payload
    },
    UPDATE_ADDRESS_ERROR(state, payload) {
      state.updateAddressError = payload
    },
    CLEAR_UPDATE_ADDRESS(state) {
      state.updateAddressError = null
      state.updateAddressProcess = null
      state.updateAddressSuccess = null
    },

    DELETE_ADDRESS_PROCESS(state, payload) {
      state.deleteAddressProcess = payload
    },
    DELETE_ADDRESS_SUCCESS(state, payload) {
      state.deleteAddressSuccess = payload
    },
    DELETE_ADDRESS_ERROR(state, payload) {
      state.deleteAddressError = payload
    },
    CLEAR_DELETE_ADDRESS(state) {
      state.deleteAddressError = null
      state.deleteAddressProcess = null
      state.deleteAddressSuccess = null
    },
  },
  actions: {
    newAddress({ commit, rootState }, payload) {
      commit('CLEAR_NEW_ADDRESS')
      commit('NEW_ADDRESS_PROCESS', true)
      const data = JSON.stringify({
        assocName: payload.assocName,
        tarifId: payload.tarifId,
        state: payload.state,
        value: payload.value,
        address: payload.address,
      })
      axios({
        method: 'post',
        url: '/tarif-receipt-address/store',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('NEW_ADDRESS_PROCESS', false)
          commit('NEW_ADDRESS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('NEW_ADDRESS_PROCESS', false)
          if (error.response) {
            commit('NEW_ADDRESS_ERROR', error.response)
          } else if (error.request) {
            commit('NEW_ADDRESS_ERROR', error.request)
          } else {
            commit('NEW_ADDRESS_ERROR', error.message)
          }
        })
    },
    updateAddress({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_ADDRESS')
      commit('UPDATE_ADDRESS_PROCESS', true)
      const data = JSON.stringify({
        id: payload.id,
        assocName: payload.assocName,
        tarifId: payload.tarifId,
        state: payload.state,
        value: payload.value,
        address: payload.address,
      })
      axios({
        method: 'post',
        url: '/tarif-receipt-address/update',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('UPDATE_ADDRESS_PROCESS', false)
          commit('UPDATE_ADDRESS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_ADDRESS_PROCESS', false)
          if (error.response) {
            commit('UPDATE_ADDRESS_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_ADDRESS_ERROR', error.request)
          } else {
            commit('UPDATE_ADDRESS_ERROR', error.message)
          }
        })
    },
    deleteAddress({ commit, rootState }, payload) {
      commit('CLEAR_DELETE_ADDRESS')
      commit('DELETE_ADDRESS_PROCESS', true)
      axios({
        method: 'delete',
        url: `/tarif-receipt-address/destroy/${payload.id}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('DELETE_ADDRESS_PROCESS', false)
          commit('DELETE_ADDRESS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_ADDRESS_PROCESS', false)
          if (error.response) {
            commit('DELETE_ADDRESS_ERROR', error.response.data.message)
          } else if (error.request) {
            commit('DELETE_ADDRESS_ERROR', error.request)
          } else {
            commit('DELETE_ADDRESS_ERROR', error.message)
          }
        })
    },
  },
}
