// eslint-disable-next-line import/no-cycle
import { getPrivileges } from '@/auth/utils'

export default [
  {
    path: '/admin',
    redirect: '/admin/dashboard',
    name: 'admin-home',
    component: () => import('@/views/app/app-admin/index.vue'),
    beforeEnter: (_to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next('login')
      }
    },
  },
  {
    path: '/admin/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/app/app-admin/dashboard/index.vue'),
    beforeEnter: (_to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next('login')
      }
    },
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin-exchange',
    name: 'admin-exchange',
    redirect: '/admin/exchange/transaction',
    component: () => import('@/views/app/app-admin/exchange/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
    children: [
      {
        path: '/admin/exchange/transaction',
        name: 'admin-exchange-transaction',
        component: () => import('@/views/app/app-admin/exchange/transaction.vue'),
        meta: {
          pageTitle: 'Exchange',
          breadcrumb: [
            {
              text: 'Transaction',
              active: true,
            },
          ],
        },
      },
      {
        path: '/admin/exchange/transaction-error/:ticket?',
        name: 'admin-exchange-transaction-error',
        component: () => import('@/views/app/app-admin/exchange/error.vue'),
        meta: {
          pageTitle: 'Exchange',
          breadcrumb: [
            {
              text: 'Transaction',
              active: true,
            },
          ],
        },
      },
      {
        path: '/admin/exchange/transaction-success/:ticket?/:status?',
        name: 'admin-exchange-transaction-success',
        component: () => import('@/views/app/app-admin/exchange/success.vue'),
        meta: {
          pageTitle: 'Exchange',
          breadcrumb: [
            {
              text: 'Transaction',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/admin-support',
    name: 'admin-support',
    component: () => import('@/views/app/app-admin/help/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Support',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin-history',
    name: 'admin-history',
    component: () => import('@/views/app/app-admin/history/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'History',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin-faq',
    name: 'admin-faq',
    component: () => import('@/views/app/app-admin/faq/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Faq',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/settings/validation-kyc',
    name: 'admin-settings-validation-kyc',
    component: () => import('@/views/app/app-admin/settings/validation/listing.vue'),
    meta: {
      pageTitle: 'My Users',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin/settings/user-transaction-history/:userId',
    name: 'admin-settings-user-transaction-history',
    component: () => import('@/views/app/app-admin/settings/validation/userHistory.vue'),
    meta: {
      pageTitle: 'Client History',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin/settings/admin-new-kyc-document',
    name: 'admin-new-kyc-document',
    component: () => import('@/views/app/app-admin/settings/document/new.vue'),
    meta: {
      pageTitle: 'My documents',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin/settings/manual-account-update',
    name: 'admin-manual-account-update',
    component: () => import('@/views/app/app-admin/settings/account/index.vue'),
    meta: {
      pageTitle: 'Compte Manuel',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin/settings/admin-all-kyc-document',
    name: 'admin-all-kyc-documents',
    component: () => import('@/views/app/app-admin/settings/document/all.vue'),
    meta: {
      pageTitle: 'My documents',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin-verification',
    name: 'admin-kyc',
    redirect: '/admin-kyc-submit',
    component: () => import('@/views/app/app-admin/verification/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin-kyc-submit',
    name: 'admin-kyc-submit',
    component: () => import('@/views/app/app-admin/verification/submit.vue'),
    meta: {
      pageTitle: 'Submit File',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin-kyc-listing',
    name: 'admin-kyc-listing',
    component: () => import('@/views/app/app-admin/verification/listing.vue'),
    meta: {
      pageTitle: 'My documents',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/admin-feedback',
    name: 'admin-feedback',
    component: () => import('@/views/app/app-admin/feedback/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/profile-pending-validation',
    name: 'admin-profile-pending-validation',
    component: () => import('@/views/app/app-admin/validation/pending.vue'),
    beforeEnter: (_to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next('login')
      }
    },
    meta: {
      pageTitle: 'KYC-Validation',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/profile-valids',
    name: 'admin-profile-valids',
    component: () => import('@/views/app/app-admin/validation/valid.vue'),
    beforeEnter: (_to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next('login')
      }
    },
    meta: {
      pageTitle: 'KYC-Validation',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin-account-settings',
    name: 'admin-account-settings',
    component: () => import('@/views/app/app-admin/user/AccountSetting.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Settings-Header',
          to: '/admin/settings/home',
        },
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/settings',
    redirect: '/admin/settings/home',
    name: 'admin-settings',
    component: () => import('@/views/app/app-admin/settings/index.vue'),
    beforeEnter: (_to, _, next) => {
      if (getPrivileges() === 'admin') {
        next()
      } else {
        next('login')
      }
    },
    children: [
      {
        path: '/admin/settings/home',
        name: 'admin-settings-home',
        component: () => import('@/views/app/app-admin/settings/Home.vue'),
        meta: {
          pageTitle: 'Settings-Header',
          breadcrumb: [
            {
              text: 'Settings-Header',
              to: '/admin/settings/home',
            },
            {
              text: 'Home',
              active: true,
            },
          ],
        },
      },
      {
        path: '/admin/settings/payment',
        name: 'admin-settings-payment',
        redirect: '/admin/settings/payment/add',
        component: () => import('@/views/app/app-admin/settings/payment/index.vue'),
        children: [
          {
            path: '/admin/settings/payment/add',
            name: 'admin-settings-payment-add',
            component: () => import('@/views/app/app-admin/settings/payment/add.vue'),
            meta: {
              pageTitle: 'Payment-Add-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/admin/settings/payment/all-payment',
            name: 'admin-settings-allpayment',
            component: () => import('@/views/app/app-admin/settings/payment/listing.vue'),
            meta: {
              pageTitle: 'Payment-Listing-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: '/admin/settings/address',
        name: 'admin-settings-address',
        redirect: '/admin/settings/address/add',
        component: () => import('@/views/app/app-admin/settings/address/index.vue'),
        children: [
          {
            path: '/admin/settings/address/add',
            name: 'admin-settings-address-add',
            component: () => import('@/views/app/app-admin/settings/address/add.vue'),
            meta: {
              pageTitle: 'Address-Add-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/admin/settings/address/all-payment',
            name: 'admin-settings-all-address',
            component: () => import('@/views/app/app-admin/settings/address/listing.vue'),
            meta: {
              pageTitle: 'Address-Listing-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: '/admin/settings/faq',
        name: 'admin-settings-faq',
        component: () => import('@/views/app/app-admin/settings/faq/index.vue'),
        children: [
          {
            component: () => import('@/views/app/app-admin/settings/faq/section/index.vue'),
            path: '/admin/settings/faq-section/add',
            children: [
              {
                path: '/admin/settings/faq-section/add',
                name: 'admin-settings-faq-section-add',
                component: () => import('@/views/app/app-admin/settings/faq/section/add.vue'),
                meta: {
                  pageTitle: 'Faq-Add-Section-Header',
                  breadcrumb: [
                    {
                      text: 'Settings-Header',
                      to: '/admin/settings/home',
                    },
                    {
                      text: 'Home',
                      active: true,
                    },
                  ],
                },
              },
              {
                path: '/admin/settings/faq-section/all',
                name: 'admin-settings-faq-section-listing',
                component: () => import('@/views/app/app-admin/settings/faq/section/listing.vue'),
                meta: {
                  pageTitle: 'Faq-Listing-Section-Header',
                  breadcrumb: [
                    {
                      text: 'Settings-Header',
                      to: '/admin/settings/home',
                    },
                    {
                      text: 'Home',
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
          {
            component: () => import('@/views/app/app-admin/settings/faq/details/index.vue'),
            path: '/admin/settings/faq-details/add',
            children: [
              {
                path: '/admin/settings/faq-details/add',
                name: 'admin-settings-faq-details-add',
                component: () => import('@/views/app/app-admin/settings/faq/details/add.vue'),
                meta: {
                  pageTitle: 'Faq-Add-Details-Header',
                  breadcrumb: [
                    {
                      text: 'Settings-Header',
                      to: '/admin/settings/home',
                    },
                    {
                      text: 'Home',
                      active: true,
                    },
                  ],
                },
              },
              {
                path: '/admin/settings/faq-details/all',
                name: 'admin-settings-faq-details-listing',
                component: () => import('@/views/app/app-admin/settings/faq/details/listing.vue'),
                meta: {
                  pageTitle: 'Faq-Listing-Details-Header',
                  breadcrumb: [
                    {
                      text: 'Settings-Header',
                      to: '/admin/settings/home',
                    },
                    {
                      text: 'Home',
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: '/admin/settings/transaction',
        name: 'admin-settings-transaction',
        redirect: '/admin/settings/transaction-history',
        component: () => import('@/views/app/app-admin/settings/transaction/index.vue'),
        children: [
          {
            path: '/admin/settings/transaction-history',
            name: 'admin-settings-transaction-history',
            component: () => import('@/views/app/app-admin/settings/transaction/history.vue'),
            meta: {
              pageTitle: 'Settings-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/admin/settings/sent-state-history',
            name: 'admin-settings-sent-state-history',
            component: () => import('@/views/app/app-admin/settings/transaction/transactionByState.vue'),
            meta: {
              pageTitle: 'Settings-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/admin/settings/close-transaction/:ticket',
            name: 'admin-settings-close-transaction',
            component: () => import('@/views/app/app-admin/settings/transaction/closeTransaction.vue'),
            meta: {
              pageTitle: 'Settings-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: '/admin/settings/support',
        name: 'admin-settings-support',
        redirect: '/admin/settings/support',
        component: () => import('@/views/app/app-admin/settings/support/index.vue'),
        children: [
          {
            path: '/admin/settings/support',
            name: 'admin-settings-support-listing',
            component: () => import('@/views/app/app-admin/settings/support/all.vue'),
            meta: {
              pageTitle: 'Settings-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: '/admin/settings/reviews',
        name: 'admin-settings-reviews',
        component: () => import('@/views/app/app-admin/settings/reviews/index.vue'),
        meta: {
          pageTitle: 'Reviews-Header',
          breadcrumb: [
            {
              text: 'Settings-Header',
              to: '/admin/settings/home',
            },
            {
              text: 'Home',
              active: true,
            },
          ],
        },
      },
      {
        path: '/admin/settings/information',
        name: 'admin-settings-information',
        redirect: '/admin/settings/information/add',
        component: () => import('@/views/app/app-admin/settings/note/index.vue'),
        children: [
          {
            path: '/admin/settings/information/add',
            name: 'admin-settings-information-add',
            component: () => import('@/views/app/app-admin/settings/note/add.vue'),
            meta: {
              pageTitle: 'Info-Add-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
          {
            path: '/admin/settings/information/all',
            name: 'admin-settings-all-information',
            component: () => import('@/views/app/app-admin/settings/note/all.vue'),
            meta: {
              pageTitle: 'Info-Listing-Header',
              breadcrumb: [
                {
                  text: 'Settings-Header',
                  to: '/admin/settings/home',
                },
                {
                  text: 'Home',
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
]
