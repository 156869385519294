import axios from '@/libs/axios'

export default {
  state: {
    validProfileProcess: null,
    validProfileSuccess: null,
    validProfileError: null,
  },
  getters: {
    validProfileProcess: state => state.validProfileProcess,
    validProfileSuccess: state => state.validProfileSuccess,
    validProfileError: state => state.validProfileError,
  },
  mutations: {

    VALID_PROFILE_PROCESS(state, payload) {
      state.validProfileProcess = payload
    },
    VALID_PROFILE_SUCCESS(state, payload) {
      state.validProfileSuccess = payload
    },
    VALID_PROFILE_ERROR(state, payload) {
      state.validProfileError = payload
    },

    CLEAR_VALID_PROFILE(state) {
      state.validProfileError = null
      state.validProfileProcess = null
      state.validProfileSuccess = null
    },
  },
  actions: {
    validProfile({ commit, rootState }, payload) {
      commit('CLEAR_VALID_PROFILE')
      commit('VALID_PROFILE_PROCESS', true)
      axios({
        method: 'post',
        url: `/user-kyc-doc/valid-profile/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('VALID_PROFILE_PROCESS', false)
          commit('VALID_PROFILE_SUCCESS', res.data)
        })
        .catch(error => {
          commit('VALID_PROFILE_PROCESS', false)
          if (error.response) {
            commit('VALID_PROFILE_ERROR', error.response)
          } else if (error.request) {
            commit('VALID_PROFILE_ERROR', error.request)
          } else {
            commit('VALID_PROFILE_ERROR', error.message)
          }
        })
    },
  },
}
