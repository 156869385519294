export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/user/Login.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/user/Register.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/user/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/user/ResetPassword.vue'),
    meta: {
      layout: 'full',
      authRequired: false,
    },
  },
  {
    path: '/confirm-account/:token',
    name: 'confirm-account',
    component: () => import('@/views/user/ConfirmAccount.vue'),
    meta: {
      layout: 'full',
      authRequired: false,
    },
  },
]
