// eslint-disable-next-line import/no-cycle
import { getPrivileges } from '@/auth/utils'

export default [
  {
    path: '/user',
    redirect: '/dashboard',
    name: 'client-home',
    component: () => import('@/views/app/app-users/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/dashboard',
    name: 'client-dashboard',
    component: () => import('@/views/app/app-users/dashboard/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/exchange',
    name: 'client-exchange',
    // redirect: '/coming-soon',
    redirect: '/exchange/transaction',
    component: () => import('@/views/app/app-users/exchange/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
    children: [
      {
        path: '/exchange/transaction',
        name: 'exchange-transaction',
        component: () => import('@/views/app/app-users/exchange/transaction.vue'),
        meta: {
          pageTitle: 'Exchange',
          breadcrumb: [
            {
              text: 'Transaction',
              active: true,
            },
          ],
        },
      },
      {
        path: '/exchange/transaction-error/:ticket?',
        name: 'exchange-transaction-error',
        component: () => import('@/views/app/app-users/exchange/error.vue'),
        meta: {
          pageTitle: 'Exchange',
          breadcrumb: [
            {
              text: 'Transaction',
              active: true,
            },
          ],
        },
      },
      {
        path: '/exchange/transaction-success/:ticket?/:status?',
        name: 'exchange-transaction-success',
        component: () => import('@/views/app/app-users/exchange/success.vue'),
        meta: {
          pageTitle: 'Exchange',
          breadcrumb: [
            {
              text: 'Transaction',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/support',
    name: 'client-support',
    component: () => import('@/views/app/app-users/help/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Support',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/history',
    name: 'client-history',
    // redirect: '/coming-soon',
    component: () => import('@/views/app/app-users/history/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'History',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/faq',
    name: 'client-faq',
    component: () => import('@/views/app/app-users/faq/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Faq',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/client-verification',
    name: 'client-kyc',
    redirect: '/client-kyc-submit',
    component: () => import('@/views/app/app-users/user/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/client-kyc-submit',
    name: 'client-kyc-submit',
    component: () => import('@/views/app/app-users/verification/submit.vue'),
    meta: {
      pageTitle: 'Submit File',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/client-kyc-listing',
    name: 'client-kyc-listing',
    component: () => import('@/views/app/app-users/verification/listing.vue'),
    meta: {
      pageTitle: 'My documents',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
  },
  {
    path: '/client-feedback',
    name: 'client-feedback',
    component: () => import('@/views/app/app-users/feedback/index.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    component: () => import('@/views/app/app-users/user/AccountSetting.vue'),
    beforeEnter: (to, _, next) => {
      if (getPrivileges() === 'client') {
        next()
      } else {
        next(to.name)
      }
    },
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
]
