import axios from '@/libs/axios'

export default {
  state: {
    newKycDocumentProcess: null,
    newKycDocumentSuccess: null,
    newKycDocumentError: null,

    validKycDocumentProcess: null,
    validKycDocumentSuccess: null,
    validKycDocumentError: null,

    rejectKycDocumentProcess: null,
    rejectKycDocumentSuccess: null,
    rejectKycDocumentError: null,

    treatKycDocumentProcess: null,
    treatKycDocumentSuccess: null,
    treatKycDocumentError: null,
  },
  getters: {
    newKycDocumentProcess: state => state.newKycDocumentProcess,
    newKycDocumentSuccess: state => state.newKycDocumentSuccess,
    newKycDocumentError: state => state.newKycDocumentError,

    validKycDocumentProcess: state => state.validKycDocumentProcess,
    validKycDocumentSuccess: state => state.validKycDocumentSuccess,
    validKycDocumentError: state => state.validKycDocumentError,

    rejectKycDocumentProcess: state => state.rejectKycDocumentProcess,
    rejectKycDocumentSuccess: state => state.rejectKycDocumentSuccess,
    rejectKycDocumentError: state => state.rejectKycDocumentError,

    treatKycDocumentProcess: state => state.treatKycDocumentProcess,
    treatKycDocumentSuccess: state => state.treatKycDocumentSuccess,
    treatKycDocumentError: state => state.treatKycDocumentError,
  },
  mutations: {
    NEW_KYC_DOCUMENT_PROCESS(state, payload) {
      state.newKycDocumentProcess = payload
    },
    NEW_KYC_DOCUMENT_SUCCESS(state, payload) {
      state.newKycDocumentSuccess = payload
    },
    NEW_KYC_DOCUMENT_ERROR(state, payload) {
      state.newKycDocumentError = payload
    },

    CLEAR_NEW_KYC_DOCUMENT(state) {
      state.newKycDocumentError = null
      state.newKycDocumentProcess = null
      state.newKycDocumentSuccess = null
    },

    VALID_KYC_DOCUMENT_PROCESS(state, payload) {
      state.validKycDocumentProcess = payload
    },
    VALID_KYC_DOCUMENT_SUCCESS(state, payload) {
      state.validKycDocumentSuccess = payload
    },
    VALID_KYC_DOCUMENT_ERROR(state, payload) {
      state.validKycDocumentError = payload
    },
    CLEAR_VALID_KYC_DOCUMENT(state) {
      state.validKycDocumentError = null
      state.validKycDocumentProcess = null
      state.validKycDocumentSuccess = null
    },

    REJECT_KYC_DOCUMENT_PROCESS(state, payload) {
      state.rejectKycDocumentProcess = payload
    },
    REJECT_KYC_DOCUMENT_SUCCESS(state, payload) {
      state.rejectKycDocumentSuccess = payload
    },
    REJECT_KYC_DOCUMENT_ERROR(state, payload) {
      state.rejectKycDocumentError = payload
    },
    CLEAR_REJECT_KYC_DOCUMENT(state) {
      state.rejectKycDocumentError = null
      state.rejectKycDocumentProcess = null
      state.rejectKycDocumentSuccess = null
    },

    TREAT_KYC_DOCUMENT_PROCESS(state, payload) {
      state.treatKycDocumentProcess = payload
    },
    TREAT_KYC_DOCUMENT_SUCCESS(state, payload) {
      state.treatKycDocumentSuccess = payload
    },
    TREAT_KYC_DOCUMENT_ERROR(state, payload) {
      state.treatKycDocumentError = payload
    },
    CLEAR_TREAT_KYC_DOCUMENT(state) {
      state.treatKycDocumentSuccess = null
      state.treatKycDocumentProcess = null
      state.treatKycDocumentError = null
    },
  },
  actions: {
    newKycDocument({ commit, rootState }, payload) {
      commit('CLEAR_NEW_KYC_DOCUMENT')
      commit('NEW_KYC_DOCUMENT_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/user-kyc-doc/store',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('NEW_KYC_DOCUMENT_PROCESS', false)
          commit('NEW_KYC_DOCUMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('NEW_KYC_DOCUMENT_PROCESS', false)
          if (error.response) {
            commit('NEW_KYC_DOCUMENT_ERROR', error.response)
          } else if (error.request) {
            commit('NEW_KYC_DOCUMENT_ERROR', error.request)
          } else {
            commit('NEW_KYC_DOCUMENT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_NEW_KYC_DOCUMENT')
          }, 3000)
        })
    },
    validKycDocument({ commit, rootState }, payload) {
      commit('CLEAR_VALID_KYC_DOCUMENT')
      commit('VALID_KYC_DOCUMENT_PROCESS', true)
      axios({
        method: 'post',
        url: `/user-kyc-doc/valid/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('VALID_KYC_DOCUMENT_PROCESS', false)
          commit('VALID_KYC_DOCUMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('VALID_KYC_DOCUMENT_PROCESS', false)
          if (error.response) {
            commit('VALID_KYC_DOCUMENT_ERROR', error.response)
          } else if (error.request) {
            commit('VALID_KYC_DOCUMENT_ERROR', error.request)
          } else {
            commit('VALID_KYC_DOCUMENT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_VALID_KYC_DOCUMENT')
          }, 3000)
        })
    },
    rejectKycDocument({ commit, rootState }, payload) {
      commit('CLEAR_REJECT_KYC_DOCUMENT')
      commit('REJECT_KYC_DOCUMENT_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/user-kyc-doc/reject',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('REJECT_KYC_DOCUMENT_PROCESS', false)
          commit('REJECT_KYC_DOCUMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('REJECT_KYC_DOCUMENT_PROCESS', false)
          if (error.response) {
            commit('REJECT_KYC_DOCUMENT_ERROR', error.response)
          } else if (error.request) {
            commit('REJECT_KYC_DOCUMENT_ERROR', error.request)
          } else {
            commit('REJECT_KYC_DOCUMENT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_REJECT_KYC_DOCUMENT')
          }, 3000)
        })
    },
    treatKycDocument({ commit, rootState }, payload) {
      commit('CLEAR_TREAT_KYC_DOCUMENT')
      commit('TREAT_KYC_DOCUMENT_PROCESS', true)
      axios({
        method: 'post',
        url: `/user-kyc-doc/set-up-verification/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('TREAT_KYC_DOCUMENT_PROCESS', false)
          commit('TREAT_KYC_DOCUMENT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('TREAT_KYC_DOCUMENT_PROCESS', false)
          if (error.response) {
            commit('TREAT_KYC_DOCUMENT_ERROR', error.response)
          } else if (error.request) {
            commit('TREAT_KYC_DOCUMENT_ERROR', error.request)
          } else {
            commit('TREAT_KYC_DOCUMENT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_TREAT_KYC_DOCUMENT')
          }, 3000)
        })
    },
  },
}
