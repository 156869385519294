import axios from '@/libs/axios'

export default {
  state: {
    faqDetailsProcess: null,
    faqDetailsSuccess: null,
    faqDetailsError: null,

    updateFaqDetailsProcess: null,
    updateFaqDetailsSuccess: null,
    updateFaqDetailsError: null,

    deleteFaqDetailsProcess: null,
    deleteFaqDetailsSuccess: null,
    deleteFaqDetailsError: null,
  },
  getters: {
    faqDetailsProcess: state => state.faqDetailsProcess,
    faqDetailsSuccess: state => state.faqDetailsSuccess,
    faqDetailsError: state => state.faqDetailsError,

    updateFaqDetailsProcess: state => state.updateFaqDetailsProcess,
    updateFaqDetailsSuccess: state => state.updateFaqDetailsSuccess,
    updateFaqDetailsError: state => state.updateFaqDetailsError,

    deleteFaqDetailsProcess: state => state.deleteFaqDetailsProcess,
    deleteFaqDetailsSuccess: state => state.deleteFaqDetailsSuccess,
    deleteFaqDetailsError: state => state.deleteFaqDetailsError,
  },
  mutations: {

    ADD_FAQ_DETAILS_PROCESS(state, payload) {
      state.faqDetailsProcess = payload
    },
    ADD_FAQ_DETAILS_SUCCESS(state, payload) {
      state.faqDetailsSuccess = payload
    },
    ADD_FAQ_DETAILS_ERROR(state, payload) {
      state.faqDetailsError = payload
    },

    CLEAR_ADD_FAQ_DETAILS(state) {
      state.faqDetailsError = null
      state.faqDetailsProcess = null
      state.faqDetailsSuccess = null
    },

    UPDATE_FAQ_DETAILS_PROCESS(state, payload) {
      state.updateFaqDetailsProcess = payload
    },
    UPDATE_FAQ_DETAILS_SUCCESS(state, payload) {
      state.updateFaqDetailsSuccess = payload
    },
    UPDATE_FAQ_DETAILS_ERROR(state, payload) {
      state.updateFaqDetailsError = payload
    },
    CLEAR_UPDATE_FAQ_DETAILS(state) {
      state.updateFaqDetailsError = null
      state.updateFaqDetailsProcess = null
      state.updateFaqDetailsSuccess = null
    },

    DELETE_FAQ_DETAILS_PROCESS(state, payload) {
      state.deleteFaqDetailsProcess = payload
    },
    DELETE_FAQ_DETAILS_SUCCESS(state, payload) {
      state.deleteFaqDetailsSuccess = payload
    },
    DELETE_FAQ_DETAILS_ERROR(state, payload) {
      state.deleteFaqDetailsError = payload
    },
    CLEAR_DELETE_FAQ_DETAILS(state) {
      state.deleteFaqDetailsError = null
      state.deleteFaqDetailsProcess = null
      state.deleteFaqDetailsSuccess = null
    },
  },
  actions: {
    newFaqDetails({ commit, rootState }, payload) {
      commit('CLEAR_ADD_FAQ_DETAILS')
      commit('ADD_FAQ_DETAILS_PROCESS', true)
      const data = JSON.stringify(payload)
      axios({
        method: 'post',
        url: '/faq-details-add',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('ADD_FAQ_DETAILS_PROCESS', false)
          commit('ADD_FAQ_DETAILS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('ADD_FAQ_DETAILS_PROCESS', false)
          if (error.response) {
            commit('ADD_FAQ_DETAILS_ERROR', error.response)
          } else if (error.request) {
            commit('ADD_FAQ_DETAILS_ERROR', error.request)
          } else {
            commit('ADD_FAQ_DETAILS_ERROR', error.message)
          }
        })
    },
    updateFaqDetails({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_FAQ_DETAILS')
      commit('UPDATE_FAQ_DETAILS_PROCESS', true)
      const data = JSON.stringify(payload.body)
      axios({
        method: 'post',
        url: `/faq-details-update/${payload.id}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
        data,
      })
        .then(res => {
          commit('UPDATE_FAQ_DETAILS_PROCESS', false)
          commit('UPDATE_FAQ_DETAILS_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_FAQ_DETAILS_PROCESS', false)
          if (error.response) {
            commit('UPDATE_FAQ_DETAILS_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_FAQ_DETAILS_ERROR', error.request)
          } else {
            commit('UPDATE_FAQ_DETAILS_ERROR', error.message)
          }
        })
    },
    deleteFaqDetails({ commit, rootState }, payload) {
      commit('CLEAR_DELETE_FAQ_DETAILS')
      commit('DELETE_FAQ_DETAILS_PROCESS', true)
      axios({
        method: 'delete',
        url: `/faq-details-delete/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(() => {
          commit('DELETE_FAQ_DETAILS_PROCESS', false)
          commit('DELETE_FAQ_DETAILS_SUCCESS', true)
        })
        .catch(error => {
          commit('DELETE_FAQ_DETAILS_PROCESS', false)
          if (error.response) {
            commit('DELETE_FAQ_DETAILS_ERROR', error.response)
          } else if (error.request) {
            commit('DELETE_FAQ_DETAILS_ERROR', error.request)
          } else {
            commit('DELETE_FAQ_DETAILS_ERROR', error.message)
          }
        })
    },
  },
}
