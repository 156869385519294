import axios from '@/libs/axios'
import userToken from '@/libs/storage/token'
import user from '@/libs/storage/user'

export default {
  state: {
    token: userToken.get() != null ? userToken.get() : null,
    user: user.get() != null ? user.get() : null,
    loginProcess: null,
    loginSuccess: null,
    loginError: null,

    resetProcess: null,
    resetSuccess: null,
    resetError: null,

    registerProcess: null,
    registerError: null,
    registerSuccess: null,

    initResetProcess: null,
    initResetError: null,
    initResetSuccess: null,

    confirmEmailProcess: null,
    confirmEmailError: null,
    confirmEmailSuccess: null,

    verifyEmailProcess: null,
    verifyEmailError: null,
    verifyEmailSuccess: null,

    updatePasswordProcess: null,
    updatePasswordError: null,
    updatePasswordSuccess: null,

    updateProfileProcess: null,
    updateProfileError: null,
    updateProfileSuccess: null,

    validAccountProcess: null,
    validAccountError: null,
    validAccountSuccess: null,

    lockAccountProcess: null,
    lockAccountError: null,
    lockAccountSuccess: null,

    loggedIn: false,
    isLocked: false,
  },
  getters: {
    currentToken: state => state.token,
    currentUser: state => state.user,
    loginProcess: state => state.loginProcess,
    loginSuccess: state => state.loginSuccess,
    loginError: state => state.loginError,

    lockAccountProcess: state => state.lockAccountProcess,
    lockAccountSuccess: state => state.lockAccountSuccess,
    lockAccountError: state => state.lockAccountError,

    resetProcess: state => state.resetProcess,
    resetSuccess: state => state.resetSuccess,
    resetError: state => state.resetError,

    registerProcess: state => state.registerProcess,
    registerSuccess: state => state.registerSuccess,
    registerError: state => state.registerError,

    initResetProcess: state => state.initResetProcess,
    initResetError: state => state.initResetError,
    initResetSuccess: state => state.initResetSuccess,

    confirmEmailProcess: state => state.confirmEmailProcess,
    confirmEmailError: state => state.confirmEmailError,
    confirmEmailSuccess: state => state.confirmEmailSuccess,

    verifyEmailProcess: state => state.verifyEmailProcess,
    verifyEmailError: state => state.verifyEmailError,
    verifyEmailSuccess: state => state.verifyEmailSuccess,

    updatePasswordProcess: state => state.updatePasswordProcess,
    updatePasswordError: state => state.updatePasswordError,
    updatePasswordSuccess: state => state.updatePasswordSuccess,

    updateProfileProcess: state => state.updateProfileProcess,
    updateProfileError: state => state.updateProfileError,
    updateProfileSuccess: state => state.updateProfileSuccess,

    validAccountProcess: state => state.validAccountProcess,
    validAccountError: state => state.validAccountError,
    validAccountSuccess: state => state.validAccountSuccess,
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },

    LOGIN_PROCESS(state, payload) {
      state.loginProcess = payload
    },
    LOGIN_SUCCESS(state, payload) {
      state.loginSuccess = payload
    },
    LOGIN_ERROR(state, payload) {
      state.loginError = payload
    },

    CLEAR_LOGIN(state) {
      state.loginError = null
      state.loginProcess = null
      state.loginSuccess = null
    },

    LOCK_ACCOUNT_PROCESS(state, payload) {
      state.lockAccountProcess = payload
    },
    LOCK_ACCOUNT_SUCCESS(state, payload) {
      state.lockAccountSuccess = payload
    },
    LOCK_ACCOUNT_ERROR(state, payload) {
      state.lockAccountError = payload
    },

    CLEAR_LOCK_ACCOUNT(state) {
      state.lockAccountError = null
      state.lockAccountProcess = null
      state.lockAccountSuccess = null
    },

    VALID_PROCESS(state, payload) {
      state.validAccountProcess = payload
    },
    VALID_SUCCESS(state, payload) {
      state.validAccountSuccess = payload
    },
    VALID_ERROR(state, payload) {
      state.validAccountError = payload
    },

    CLEAR_VALID(state) {
      state.validAccountError = null
      state.validAccountProcess = null
      state.validAccountSuccess = null
    },

    RESET_PROCESS(state, payload) {
      state.resetProcess = payload
    },
    RESET_SUCCESS(state, payload) {
      state.resetSuccess = payload
    },
    RESET_ERROR(state, payload) {
      state.resetError = payload
    },
    CLEAR_RESET(state) {
      state.resetError = null
      state.resetProcess = null
      state.resetSuccess = null
    },

    REGISTER_PROCESS(state, payload) {
      state.registerProcess = payload
    },
    REGISTER_SUCCESS(state, payload) {
      state.registerSuccess = payload
    },
    REGISTER_ERROR(state, payload) {
      state.registerError = payload
    },
    CLEAR_REGISTER(state) {
      state.registerError = null
      state.registerProcess = null
      state.registerSuccess = null
    },

    // Confirm Email
    CONFIRM_PROCESS(state, payload) {
      state.confirmEmailProcess = payload
    },
    CONFIRM_SUCCESS(state, payload) {
      state.confirmEmailSuccess = payload
    },
    CONFIRM_ERROR(state, payload) {
      state.confirmEmailError = payload
    },
    CLEAR_CONFIRM(state) {
      state.confirmEmailError = null
      state.confirmEmailProcess = null
      state.confirmEmailSuccess = null
    },

    RESET_PASSWORD_PROCESS(state, payload) {
      state.initResetProcess = payload
    },
    RESET_PASSWORD_SUCCESS(state, payload) {
      state.initResetSuccess = payload
    },
    RESET_PASSWORD_ERROR(state, payload) {
      state.initResetError = payload
    },
    RESET_PASSWORD_CLEAR(state) {
      state.initResetError = null
      state.initResetSuccess = null
      state.initResetProcess = null
    },

    // Verify User

    VERIFY_PROCESS(state, payload) {
      state.verifyEmailProcess = payload
    },
    VERIFY_SUCCESS(state, payload) {
      state.verifyEmailSuccess = payload
    },
    VERIFY_ERROR(state, payload) {
      state.verifyEmailError = payload
    },
    CLEAR_VERIFY(state) {
      state.verifyEmailError = null
      state.verifyEmailSuccess = null
      state.verifyEmailProcess = null
    },

    // Update Password

    UPDATE_PASSWORD_PROCESS(state, payload) {
      state.updatePasswordProcess = payload
    },
    UPDATE_PASSWORD_SUCCESS(state, payload) {
      state.updatePasswordSuccess = payload
    },
    UPDATE_PASSWORD_ERROR(state, payload) {
      state.updatePasswordError = payload
    },
    CLEAR_UPDATE_PASSWORD(state) {
      state.updatePasswordProcess = null
      state.updatePasswordSuccess = null
      state.updatePasswordError = null
    },

    // Update Profile

    UPDATE_PROFILE_PROCESS(state, payload) {
      state.updateProfileProcess = payload
    },
    UPDATE_PROFILE_SUCCESS(state, payload) {
      state.updateProfileSuccess = payload
    },
    UPDATE_PROFILE_ERROR(state, payload) {
      state.updateProfileError = payload
    },
    CLEAR_UPDATE_PROFILE(state) {
      state.updateProfileProcess = null
      state.updateProfileSuccess = null
      state.updateProfileError = null
    },
    LOCKED(state, payload) {
      state.isLocked = payload
    },
  },
  actions: {
    updateUser({ commit }, payload) {
      commit('SET_USER', payload)
      user.set(payload)
    },
    validAccount({ commit, state }, payload) {
      commit('CLEAR_VALID')
      commit('VALID_PROCESS', true)
      const data = JSON.stringify({
        id: payload.id,
      })
      axios({
        method: 'put',
        url: '/validate-user-profile',
        data,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then(res => {
          commit('VALID_PROCESS', false)
          commit('VALID_SUCCESS', res.data)
        })
        .catch(error => {
          commit('VALID_PROCESS', false)
          if (error.response) {
            commit('VALID_ERROR', error.response)
          } else if (error.request) {
            commit('VALID_ERROR', error.request)
          } else {
            commit('VALID_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_VALID')
          }, 3000)
        })
    },
    addAdmin() {
      axios({
        method: 'post',
        url: '/register-admin',
      })
        .then(() => {})
        .catch(() => {})
    },
    loginUser({ commit, dispatch }, payload) {
      commit('CLEAR_LOGIN')
      commit('LOGIN_PROCESS', true)
      const data = JSON.stringify({
        email: payload.username,
        password: payload.password,
      })
      axios({
        method: 'post',
        url: '/login',
        data,
      })
        .then(res => {
          const { token } = res.data
          if (typeof token === 'undefined') {
            commit('LOGIN_PROCESS', false)
            commit('LOCKED', res.data.message)
            commit('LOGIN_ERROR', true)
          } else {
            commit('SET_TOKEN', token)
            userToken.set(token)
            dispatch('getUser')
          }
        })
        .catch(error => {
          commit('LOGIN_PROCESS', false)
          if (error.response) {
            commit('LOGIN_ERROR', error.response)
          } else if (error.request) {
            commit('LOGIN_ERROR', error.request)
          } else {
            commit('LOGIN_ERROR', error.message)
          }
        })
    },
    registerUser({ commit }, payload) {
      commit('CLEAR_REGISTER')
      commit('REGISTER_PROCESS', true)
      const data = {
        address: null,
        name: payload.name,
        surname: payload.surname,
        callback: `${window.location.protocol}//${window.location.hostname}/confirm-account`,
        phone: payload.phone,
        country: payload.country,
        email: payload.email,
        password: payload.password,
        password_confirmation: payload.password_confirmation,
      }
      axios({
        method: 'post',
        url: '/register',
        data,
      })
        .then(res => {
          commit('REGISTER_PROCESS', false)
          commit('REGISTER_SUCCESS', res.data)
        })
        .catch(error => {
          commit('REGISTER_PROCESS', false)
          if (error.response) {
            commit('REGISTER_ERROR', error.response)
          } else if (error.request) {
            commit('REGISTER_ERROR', error.request)
          } else {
            commit('REGISTER_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_REGISTER')
          }, 3000)
        })
    },
    getUser({ commit, state, dispatch }) {
      axios
        .get('/user', {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          const { data } = res
          if (data.status) {
            commit('SET_USER', data)
            user.set(data)
            commit('LOGIN_SUCCESS', res.data)
            commit('LOGIN_PROCESS', false)
          } else {
            commit('LOGIN_PROCESS', false)
            dispatch('verifyUser')
          }
        })
        .catch(error => {
          commit('LOGIN_PROCESS', false)
          if (error.response) {
            commit('LOGIN_ERROR', error.response)
          } else if (error.request) {
            commit('LOGIN_ERROR', error.request)
          } else {
            commit('LOGIN_ERROR', error.message)
          }
        })
    },
    verifyUser({ commit, state }) {
      commit('CLEAR_VERIFY')
      commit('VERIFY_PROCESS', true)
      const data = JSON.stringify({
        callback_url: `${window.location.protocol}//${window.location.hostname}/confirm-account`,
      })
      axios
        .post('/auth/verify-or-send-new-mail', data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          userToken.remove()
          commit('SET_TOKEN', null)
          commit('VERIFY_PROCESS', false)
          commit('VERIFY_SUCCESS', res.data)
        })
        .catch(error => {
          userToken.remove()
          commit('SET_TOKEN', null)
          commit('VERIFY_PROCESS', false)
          if (error.response) {
            commit('VERIFY_ERROR', error.response)
          } else if (error.request) {
            commit('VERIFY_ERROR', error.request)
          } else {
            commit('VERIFY_ERROR', error.message)
          }
        })
    },
    confirmUserEmail({ commit }, payload) {
      commit('CLEAR_CONFIRM')
      commit('CONFIRM_PROCESS', true)
      const data = JSON.stringify({
        token: payload.token,
        callback_url: `${window.location.protocol}//${window.location.hostname}`,
      })
      axios
        .post('/auth/execute-confirm-mail', data)
        .then(res => {
          commit('CONFIRM_PROCESS', false)
          commit('CONFIRM_SUCCESS', res.data)
        })
        .catch(error => {
          commit('CONFIRM_PROCESS', false)
          if (error.response) {
            commit('CONFIRM_ERROR', error.response)
          } else if (error.request) {
            commit('CONFIRM_ERROR', error.request)
          } else {
            commit('CONFIRM_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_CONFIRM')
          }, 3000)
        })
    },
    initPassReset({ commit }, payload) {
      commit('CLEAR_RESET')
      commit('RESET_PROCESS', true)
      const data = JSON.stringify({
        email: payload.email,
        callback: `${window.location.protocol}//${window.location.hostname}/reset-password`,
      })
      axios
        .post('/init-reset-password', data)
        .then(res => {
          commit('RESET_PROCESS', false)
          commit('RESET_SUCCESS', res.data)
        })
        .catch(error => {
          commit('RESET_PROCESS', false)
          if (error.response) {
            commit('RESET_ERROR', error.response)
          } else if (error.request) {
            commit('RESET_ERROR', error.request)
          } else {
            commit('RESET_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_RESET')
          }, 3000)
        })
    },
    resetPassword({ commit }, payload) {
      commit('RESET_PASSWORD_CLEAR')
      commit('RESET_PASSWORD_PROCESS', true)
      const data = JSON.stringify({
        token: payload.token,
        password: payload.password,
        callback: `${window.location.protocol}//${window.location.hostname}`,
      })
      axios
        .post('/reset-password', data)
        .then(res => {
          commit('RESET_PASSWORD_PROCESS', false)
          commit('RESET_PASSWORD_SUCCESS', res.data)
        })
        .catch(error => {
          commit('RESET_PASSWORD_PROCESS', false)
          if (error.response) {
            commit('RESET_PASSWORD_ERROR', error.response)
          } else if (error.request) {
            commit('RESET_PASSWORD_ERROR', error.request)
          } else {
            commit('RESET_PASSWORD_ERROR', error.message)
          }
          setTimeout(() => {
            commit('RESET_PASSWORD_CLEAR')
          }, 3000)
        })
    },
    updatePassword({ commit, state }, payload) {
      commit('CLEAR_UPDATE_PASSWORD')
      commit('UPDATE_PASSWORD_PROCESS', true)
      const data = JSON.stringify({
        password: payload.password,
      })
      axios
        .post('/update-password', data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          commit('UPDATE_PASSWORD_PROCESS', false)
          commit('UPDATE_PASSWORD_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_PASSWORD_PROCESS', false)
          if (error.response) {
            commit('UPDATE_PASSWORD_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_PASSWORD_ERROR', error.request)
          } else {
            commit('UPDATE_PASSWORD_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_UPDATE_PASSWORD')
          }, 3000)
        })
    },
    updateProfile({ commit, state }, payload) {
      commit('CLEAR_UPDATE_PROFILE')
      commit('UPDATE_PROFILE_PROCESS', true)
      const data = JSON.stringify({
        name: payload.name,
        surname: payload.surname,
        country: payload.country,
        address: payload.address,
        sex: payload.sex,
        city: payload.city,
        birthDate: payload.birthDate,
      })
      axios
        .post('/update-user-info', data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          commit('UPDATE_PROFILE_PROCESS', false)
          commit('UPDATE_PROFILE_SUCCESS', res.data)
          commit('SET_USER', res.data)
          user.set(res.data)
        })
        .catch(error => {
          commit('UPDATE_PROFILE_PROCESS', false)
          if (error.response) {
            commit('UPDATE_PROFILE_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_PROFILE_ERROR', error.request)
          } else {
            commit('UPDATE_PROFILE_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_UPDATE_PROFILE')
          }, 3000)
        })
    },
    lockAccount({ commit, state }, payload) {
      commit('CLEAR_LOCK_ACCOUNT')
      commit('LOCK_ACCOUNT_PROCESS', true)
      const data = JSON.stringify({
        id: payload,
      })
      axios
        .post('/change-lock-state', data, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(res => {
          commit('LOCK_ACCOUNT_PROCESS', false)
          commit('LOCK_ACCOUNT_SUCCESS', res.data)
        })
        .catch(error => {
          commit('LOCK_ACCOUNT_PROCESS', false)
          if (error.response) {
            commit('LOCK_ACCOUNT_ERROR', error.response)
          } else if (error.request) {
            commit('LOCK_ACCOUNT_ERROR', error.request)
          } else {
            commit('LOCK_ACCOUNT_ERROR', error.message)
          }
          setTimeout(() => {
            commit('CLEAR_LOCK_ACCOUNT')
          }, 3000)
        })
    },
  },
}
