import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Vuelidate from 'vuelidate'
import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/phone-input'
import '@/libs/moment'
import '@/libs/sweet-alerts'
import '@/libs/axios'
import '@/libs/toastification'
import '@/libs/firebase'
import '@/libs/clipboard'
import '@/libs/tel-input'
import '@/libs/sanitize'
import './registerServiceWorker'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// Validation
Vue.use(Vuelidate)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// const prod = process.env.NODE_ENV === 'production'
// const shouldSW = 'serviceWorker' in navigator && prod
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`, { scope: '/' })
// }
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
