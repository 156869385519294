import axios from '@/libs/axios'

export default {
  state: {
    noteProcess: null,
    noteSuccess: null,
    noteError: null,

    updateNoteProcess: null,
    updateNoteSuccess: null,
    updateNoteError: null,

    deleteNoteProcess: null,
    deleteNoteSuccess: null,
    deleteNoteError: null,
  },
  getters: {
    noteProcess: state => state.noteProcess,
    noteSuccess: state => state.noteSuccess,
    noteError: state => state.noteError,

    updateNoteProcess: state => state.updateNoteProcess,
    updateNoteSuccess: state => state.updateNoteSuccess,
    updateNoteError: state => state.updateNoteError,

    deleteNoteProcess: state => state.deleteNoteProcess,
    deleteNoteSuccess: state => state.deleteNoteSuccess,
    deleteNoteError: state => state.deleteNoteError,
  },
  mutations: {

    ADD_INFORMATION_PROCESS(state, payload) {
      state.noteProcess = payload
    },
    ADD_INFORMATION_SUCCESS(state, payload) {
      state.noteSuccess = payload
    },
    ADD_INFORMATION_ERROR(state, payload) {
      state.noteError = payload
    },

    CLEAR_ADD_INFORMATION(state) {
      state.noteError = null
      state.noteProcess = null
      state.noteSuccess = null
    },

    UPDATE_INFORMATION_PROCESS(state, payload) {
      state.updateNoteProcess = payload
    },
    UPDATE_INFORMATION_SUCCESS(state, payload) {
      state.updateNoteSuccess = payload
    },
    UPDATE_INFORMATION_ERROR(state, payload) {
      state.updateNoteError = payload
    },
    CLEAR_UPDATE_INFORMATION(state) {
      state.updateNoteError = null
      state.updateNoteProcess = null
      state.updateNoteSuccess = null
    },

    DELETE_INFORMATION_PROCESS(state, payload) {
      state.deleteNoteProcess = payload
    },
    DELETE_INFORMATION_SUCCESS(state, payload) {
      state.deleteNoteSuccess = payload
    },
    DELETE_INFORMATION_ERROR(state, payload) {
      state.deleteNoteError = payload
    },
    CLEAR_DELETE_INFORMATION(state) {
      state.deleteNoteError = null
      state.deleteNoteProcess = null
      state.deleteNoteSuccess = null
    },
  },
  actions: {
    note({ commit, rootState }, payload) {
      commit('CLEAR_ADD_INFORMATION')
      commit('ADD_INFORMATION_PROCESS', true)
      const data = new FormData()
      data.append('information', payload.information)
      data.append('type', payload.type)
      data.append('state', payload.state)
      if (payload.file) {
        data.append('file', payload.file)
      }
      axios({
        method: 'post',
        url: '/add-information',
        data,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(res => {
          commit('ADD_INFORMATION_PROCESS', false)
          commit('ADD_INFORMATION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('ADD_INFORMATION_PROCESS', false)
          if (error.response) {
            commit('ADD_INFORMATION_ERROR', error.response)
          } else if (error.request) {
            commit('ADD_INFORMATION_ERROR', error.request)
          } else {
            commit('ADD_INFORMATION_ERROR', error.message)
          }
        })
    },
    updateNote({ commit, rootState }, payload) {
      commit('CLEAR_UPDATE_INFORMATION')
      commit('UPDATE_INFORMATION_PROCESS', true)
      const data = new FormData()
      data.append('id', payload.id)
      data.append('information', payload.information)
      data.append('type', payload.type)
      data.append('state', payload.state)
      if (payload.file) {
        data.append('file', payload.file)
      }
      axios({
        method: 'post',
        url: '/information-update',
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
          'Content-Type': 'multipart/form-data',
        },
        data,
      })
        .then(res => {
          commit('UPDATE_INFORMATION_PROCESS', false)
          commit('UPDATE_INFORMATION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('UPDATE_INFORMATION_PROCESS', false)
          if (error.response) {
            commit('UPDATE_INFORMATION_ERROR', error.response)
          } else if (error.request) {
            commit('UPDATE_INFORMATION_ERROR', error.request)
          } else {
            commit('UPDATE_INFORMATION_ERROR', error.message)
          }
        })
    },
    deleteNote({ commit, rootState }, payload) {
      commit('CLEAR_DELETE_INFORMATION')
      commit('DELETE_INFORMATION_PROCESS', true)
      axios({
        method: 'delete',
        url: `/information-delete/${payload}`,
        headers: {
          Authorization: `Bearer ${rootState.auth.token}`,
        },
      })
        .then(res => {
          commit('DELETE_INFORMATION_PROCESS', false)
          commit('DELETE_INFORMATION_SUCCESS', res.data)
        })
        .catch(error => {
          commit('DELETE_INFORMATION_PROCESS', false)
          if (error.response) {
            commit('DELETE_INFORMATION_ERROR', error.response)
          } else if (error.request) {
            commit('DELETE_INFORMATION_ERROR', error.request)
          } else {
            commit('DELETE_INFORMATION_ERROR', error.message)
          }
        })
    },
  },
}
